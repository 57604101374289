require('typeface-roboto')
require('typeface-comfortaa')
require('typeface-reenie-beanie')
require('typeface-roboto-mono')

export default {
  breakpoints: ['425px', '600px', '900px', '1200px', '1800px'],
  colors: {
    text: '#fff',
    background: '#040410',
    white: '#fff',
    green: '#1EE3CF',
    purple: '#6F67FF',
    darkBlue: '#0D3F67',
    navBg: '#040410f2',
    modes: {
      light: {
        text: '#142A4C',
        background: '#eaebfb',
        navBg: '#eaebfbf2',
      },
    },
  },
  fonts: {
    body: 'Roboto, sans-serif',
    logo: 'Comfortaa, sans-serif',
    monospace: 'Roboto Mono, monospace',
    signing: 'Reenie Beanie',
  },
  fontWeights: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900,
  },
  fontSizes: [12, 14, 16, 20, 24, 28, 32, 48, 64, 80, 96],
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  space: [0, 8, 12, 16, 32, 64, 128, 192],
  text: {
    landing: {
      fontSize: [7, 7, 8, 9, 10],
      fontWeight: 'bold',
      fontFamily: 'body',
      textAlign: 'center',
    },
    heading: {
      fontSize: [5, 5, 6, 7, 8],
      fontWeight: 'bold',
      fontFamily: 'body',
    },
    paragraph: {
      fontSize: [3, 3, 4, 5, 6],
      fontWeight: 'light',
      fontFamily: 'logo',
    },
    staffName: {
      fontSize: [7, 8],
      fontFamily: 'signing',
    },
    boldText: {
      fontWeight: 'bold',
      fontSize: [4, 5],
      mt: 3,
    },
    smallText: {
      my: 2,
      fontSize: [1, 2],
      fontWeight: 'regular',
      color: 'text',
    },
    smallTextPurple: {
      my: 2,
      fontSize: [1, 2],
      fontWeight: 'regular',
      color: 'purple',
      mt: 4,
    },
    largeEmailText: {
      mt: 3,
      fontWeight: 'bold',
      fontSize: [3, 4, 5],
    },

    navLink: {
      my: 4,
      fontSize: 7,
      fontFamily: 'body',
      fontWeight: 'bold',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
    },
  },
  variants: {
    layout: {
      fontFamily: 'body',
      backgroundColor: 'background',
      color: 'text',
      px: [3, 3, 5, 6, 7],
      transition: 'all 0.3s ease-in',
      maxWidth: '1920px',
      mx: 'auto',
    },
    navbar: {
      pt: 3,
      px: 3,
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'fixed',
      width: '100%',
      backgroundColor: 'navBg',
      transition: 'all 0.3s ease-in',
    },
    nav: {
      height: '100%',
      width: '100%',
      position: 'fixed',
      zIndex: 2,
      left: 0,
      top: 0,
      overflow: 'hidden',
      transition: '0.7s',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    themeToggle: {
      color: 'text',
      backgroundColor: 'transparent',
      transition: 'all 0.3s ease-in',
    },
    navLogo: {
      width: '150px',
    },
    emailLink: {
      color: 'text',
      textDecoration: 'none',
      '&:hover': { color: 'green', transition: '0.3s ease-in-out' },
    },
    staffImage: {
      mx: 'auto',
      width: '240px',
      height: 'auto',
      borderRadius: '5px',
    },

    terminalOuterBox: {
      zIndex: '10',
      position: 'fixed',
      bottom: '0',
      right: '0',
      color: 'white',
      marginBottom: '0',
    },
    terminalInnerBox: {
      borderRadius: '5px',
      backgroundColor: '#161616',
      height: '240px',
    },
    terminalHeader: {
      backgroundColor: '#2a2a2a',
      borderRadius: '5px 5px 0 0',
    },
    terminalButton: {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      marginLeft: 1,
    },
    terminalTextBox: {
      color: 'white',
      fontFamily: 'monospace',
      fontSize: '12px',
      px: '6px',
      py: 1,
    },
    terminalText: {
      fontFamily: 'monospace',
      fontSize: '12px',
    },
    footer: {
      fontFamily: 'body',
    },
  },
}
